import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import { Form, Button, Alert } from 'react-bootstrap';
import Logo from '../assets/logo.png'
import { validateEmail } from '../utilities/StringUtils'
import { Authenticate, ResetPasswordNotLoggedIn } from '../network/ApiManager'

class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      resetEmail: '',
      error: null,
      showResetPasswordPopup: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetEmail = this.resetEmail.bind(this);
    this.toggleResetPassword = this.toggleResetPassword.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  toggleResetPassword() {
    this.setState({ showResetPasswordPopup: !this.state.showResetPasswordPopup });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { onSignIn } = this.props;
    const { email, password } = this.state;

    if (email && password && password.length > 0 && validateEmail(email)) {
      Authenticate(email, password, () => {
        if (onSignIn) {
          onSignIn(email)
          this.setState({
            email: '',
            password: '',
            error: null,
          });
        }
      }, (error) => {
        this.setState({ error: 'There was a problem signing in.' });
      });
    }
    else {
      this.setState({ error: 'There was a problem signing in.' });
    }
  }

  resetEmail() {
    const { resetEmail } = this.state;
    this.setState({ showResetPasswordPopup: false });
    if (resetEmail && validateEmail(resetEmail)) {
      ResetPasswordNotLoggedIn(resetEmail, () => {
          alert(`Password reset instructions were sent to ${resetEmail}.`)
      }, (error) => {
          alert('There was a problem resetting your email.')
      })
    }
    else {
      alert('Enter a valid email.')
    }
  }

  renderShowPasswordResetPassword() {
    const { resetEmail } = this.state;
    return (<div style={{
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }}
      onClick={ this.toggleResetPassword }
    >
      <Alert
        show
        variant="primary"
        style={{
          width: '600px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        onClick={ (e) => { e.stopPropagation() } }
      >
        <Form
            style={{
              margin: 'auto',
              padding: '20px',
              maxWidth: '800px'
            }}
            onSubmit={this.handleResetPasswordSubmit}
          >
          <Form.Group controlId="formSignInEmail">
            <Form.Control type="email" placeholder="Enter email to send password reset to." name="resetEmail" value={resetEmail} onChange={this.handleChange} />
          </Form.Group>
        <div className="d-flex justify-content-end">
          <Button variant="danger" onClick={ this.resetEmail }>
            Reset
          </Button>
        </div>
        </Form>
      </Alert>
    </div>)
  }

  render() {
    const { email, password, error, showResetPasswordPopup } = this.state;
    const labelStyle = error ? { color: 'red' } : {};
    return (
      <>
      {showResetPasswordPopup && this.renderShowPasswordResetPassword()}
      <div
        style={{
          padding: '20px',
        }}
      >
        <Form
            style={{
              margin: 'auto',
              marginTop: '80px',
              padding: '20px',
              maxWidth: '800px',
              border: '2px solid #CED4DA',
            }}
            onSubmit={this.handleSubmit}
          >
          <div
            style={{
            }}
          >
            <img
              alt=""
              src={Logo}
              style={{
                position: 'relative',
                top: '50%',
                transform: 'translateY(-20%)',
                margin: 'auto',
                display: 'inline-block',
                width: '32px',
                height: '32px',
              }}
            />
            <h2
              style={{
                margin: 'auto',
                display: 'inline-block',
                marginLeft: '10px',
              }}
            >
              Legal Review
            </h2>
          </div>
          <div style={{ height: '20px' }} />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Form.Group controlId="formSignInEmail">
            <Form.Label style={labelStyle}>
              Email
            </Form.Label>
            <Form.Control type="email" placeholder="Enter your email" name="email" value={email} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group controlId="formSignInPassword">
            <Form.Label style={labelStyle}>
              Password
            </Form.Label>
            <Form.Control type="password" placeholder="Enter your password" name="password" value={password} onChange={this.handleChange} />
          </Form.Group>
          <div style={{ marginTop: '40px' }}>
            <Button variant="primary" type="submit" style={{ width: '100px' }}>
              Sign In
            </Button>
            <div style={{ float: 'right' }}>
              <a href="/" onClick={(e) => { e.preventDefault(); this.toggleResetPassword(); }}>Reset Password</a>
            </div>
          </div>
        </Form>
      </div>
      </>
    );
  }
}

export default SignIn;
