import 'bootstrap/dist/css/bootstrap.min.css';
import './style/shared.css';
import React from 'react'
import { dateToDateTimeString, dateToDateString } from '../utilities/DateFormatting.js'
import { bucketClaimsByDay } from '../utilities/ClaimUtils.js'
import { enumToPresentableString } from '../utilities/StringUtils.js'
import { GetArchivedClaims } from '../network/ApiManager'
import { Table, Button } from 'react-bootstrap';
import EditClaim from './EditClaim.js'

class ArchivedClaims extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popupClaim: null, claims: [] }
    this.selectClaim = this.selectClaim.bind(this);
    this.rowsForClaim = this.rowsForClaim.bind(this);
  }

  componentDidMount() {
    GetArchivedClaims((claims) => {
       const buckets = bucketClaimsByDay(claims)
       this.setState({ claims: buckets })
    }, (error) => {
        console.error(`${JSON.stringify(error)}`);
    })
  }

  selectClaim(claim) {
    this.setState({ popupClaim: claim })
  }

  editClaim(claim) {
    if (claim) {
      const updatedClaim = claim;
      const claims = this.state.claims.map(cs => cs.map(c => c.id === updatedClaim.id ? updatedClaim : c));

      this.setState({ popupClaim: null, claims })
    }
    else {
      this.setState({ popupClaim: null })
    }
  }

  rowColor(claim) {
    if (claim.fileRetainedStatus === 'RETAINED') {
      return '#E1EFDA';
    } else if (claim.fileRetainedStatus === 'NOT_RETAINED') {
      return '#FFB0A7';
    } else {
      return '#E7E6E6';
    }
  }

  linkToClaim(claim) {
    if (claim.pdfUrl) {
      const name = claim.pdfUrl.split('/').pop()
      return (<a target="_blank" rel="noreferrer" href={`${claim.pdfUrl}`}>{name}</a>)
    }
    else {
      return (<div/>)
    }
  }

  rowsForClaim(claim) {
    const { isAdmin } = this.props;
    const reviewLink = `/claim/${claim.id}/review`

    let averageRating = 0.0
    if (claim.review) {
      const { speedOfService, qualityOfReview, friendlinessOfLawyer } = claim.review
      averageRating = (speedOfService + qualityOfReview + friendlinessOfLawyer) / 3.0
    }

    return (
        <tr
          key={claim.id}
          style={{ backgroundColor: this.rowColor(claim) }}
        >
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>
            <Button
              onClick={ () => {
                  this.selectClaim(claim)
              }}
              variant="primary"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {isAdmin ? 'Edit' : 'Notes'}
            </Button>
          </td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{enumToPresentableString(claim.requestType)}</td>
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word' }}>{this.linkToClaim(claim)}</td>
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word' }}>{claim.senderDescriptor}</td>
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word' }}>{claim.howDidYouHearAboutUs}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{dateToDateTimeString(claim.timeReceived)}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{dateToDateTimeString(claim.timeClaimed)}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{dateToDateTimeString(claim.responseTime)}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{enumToPresentableString(claim.fileRetainedStatus || 'Pending')}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{claim.ownership}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{enumToPresentableString(claim.clientType)}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'normal' }}>{claim.lawyerNotes}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'normal' }}>{claim.adminNotes}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{claim.hasReview ? (<div>{` (\u2605 ${averageRating.toFixed(2)})`}<br/><a href={reviewLink}>More Details</a></div>) : (<div/>)}</td>
        </tr>
    );
  }

  render() {
    const { popupClaim } = this.state;
    const claimsByDay = this.state.claims;
    const { isAdmin } = this.props;

    if (popupClaim) {
      return (<EditClaim isAdmin={isAdmin} claim={popupClaim} didUpdateClaim={(claim) => this.editClaim(claim)} />);
    }

    return (
      <div style={{ padding: '20px' }}>
        {claimsByDay.map((claims) => (
        <Table
            key={claims[0].id}
            style={{
              width: '100%',
              marginBottom: -1,
            }}
        >
          <thead>
            <tr style={{ width: '100%' }}>
              <th colSpan='14'>{dateToDateString(claims[0].timeReceived)}</th>
            </tr>
            <tr>
              <th/>
              <th>File Type</th>
              <th>Claim File</th>
              <th>Sender Info</th>
              <th>Source</th>
              <th>Time Received</th>
              <th>Time Claimed</th>
              <th>Response Time</th>
              <th>File Retained</th>
              <th>Ownership</th>
              <th>Client Type</th>
              <th>Lawyer Notes</th>
              <th>Admin Notes</th>
              <th>Client Feedback</th>
            </tr>
          </thead>
          <tbody>
            {claims.map((claim) => ( this.rowsForClaim(claim) ))}
          </tbody>
        </Table>
        ))}
      </div>
    );
  }
}

export default ArchivedClaims;
