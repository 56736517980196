import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { dateToDateTimeString, formDateStringFormat, monthAndDate, justMonth } from '../utilities/DateFormatting.js'
import { Form, Button, Table } from 'react-bootstrap'
import { GetReports, DeleteReport, CreateReport, GetClaimStats } from '../network/ApiManager'
import { withRouter } from 'react-router';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

class Reports extends React.Component {
  constructor(props) {
    super(props);
    const endDate = new Date();
    var startDate = new Date();
    startDate.setFullYear(endDate.getFullYear() - 1);
    this.state = {
      startDate: formDateStringFormat(startDate),
      endDate: formDateStringFormat(endDate),
      reports: [],
      sourcesThisMonth: [],
      sourcesAllTime: [],
      ownersThisMonth: [],
      ownersAllTime: [],
      claimsThisWeek: [],
      claimsThisMonth: [],
      claimsThisYear: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  refreshReportsList() {
    GetReports((reports) => {
      this.setState({ reports })
    }, (error) => {
      alert(error)
    })
  }

  deleteReport(report) {
    DeleteReport(report.id, () => {
      this.refreshReportsList()
    }, (error) => {
      alert(error)
    })
  }

  componentDidMount() {
    this.refreshReportsList()

    GetClaimStats((stats) => {
      this.setState({
        claimsThisWeek: stats.claimsThisWeek,
        claimsThisMonth: stats.claimsThisMonth,
        claimsThisYear: stats.claimsThisYear,
        sourcesThisMonth: stats.sourcesThisMonth,
        sourcesAllTime: stats.sourcesAllTime,
        ownersThisMonth: stats.ownersThisMonth,
        ownersAllTime: stats.ownersAllTime
      })
    }, (error) => {
      alert(error)
    })
  }

  dateFromFormDate(formDate) {
    const components = formDate.split("-")
    const year = parseInt(components[0])
    const month = parseInt(components[1])
    const day = parseInt(components[2])

    const date = new Date(year, month - 1, day, 0, 0, 0, 0);
    return date.getTime() / 1000.0
  }

  onSubmit() {
    const { startDate, endDate } = this.state;

    CreateReport(this.dateFromFormDate(startDate), this.dateFromFormDate(endDate), (report) => {
      this.refreshReportsList();
    }, (error) => {
      alert(error)
    })
  }

  linkToReport(report) {
    if (report.csvFile) {
      const isS3 = report.csvFile.includes(".s3.us-east-2.amazonaws.com")
      const name = isS3 ? report.csvFile.split('/').pop() : report.csvFile
      return (<a target="_blank" rel="noreferrer" href={`${report.csvFile}`}>{name}</a>)
    }
    else {
      return (<div/>)
    }
  }

  formattedData(data, dateFormatter = monthAndDate) {
    return data.map((d) => ( { count: d.count, date: dateFormatter(d.date) } )).reverse()
  }

  formattedNoDateData(data, dateFormatter = monthAndDate) {
    return data
            .filter(d => !!d.displayDate)
            .filter(d => !d.displayDate.includes('test'))
            .map((d) => ( { count: d.count, date: d.displayDate } ))
            .reverse()
  }

  rowsForReport(report) {
    return (
        <tr
          key={report.id}
          style={{ backgroundColor: '#E7E6E6' }}
        >
          <td>{dateToDateTimeString(report.createdAt)}</td>
          <td>{dateToDateTimeString(report.startDate)}</td>
          <td>{dateToDateTimeString(report.endDate)}</td>
          <td style={{ maxWidth: '400px', overflow: 'hidden' }} >{this.linkToReport(report)}</td>
          <td>
            <Button
              onClick={ () => {
                  this.deleteReport(report)
              }}
              variant="primary"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              Delete
            </Button>
          </td>
        </tr>
    );
  }

  renderChart(name, data, scaleToFit = false) {
    return (
      <div style={{ marginTop: '20px', marginBottom: '20px', float: 'left' }}>
        <div style={{ width: '500px', textAlign: 'center', fontWeight: '600' }}>{name}</div>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 10, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="date" hide={scaleToFit} />
          <YAxis dataKey="count" />
          <Tooltip />
          <CartesianGrid stroke="#f5f5f5" />
          <Bar dataKey="count" fill="#007bff" />
        </BarChart>
      </div>
    )
  }

  render() {
    const { startDate, endDate, reports } = this.state;
    const { claimsThisWeek, claimsThisMonth, claimsThisYear } = this.state;
    const { sourcesThisMonth, sourcesAllTime } = this.state;
    const { ownersThisMonth, ownersAllTime } = this.state;
    const noReports = reports.length === 0;
    return (
      <div>
        <div>
          <div style={{ float: 'left', display: 'inline-block', textAlign: 'center' }}>
          { claimsThisWeek.length > 0 && this.renderChart("Claims This Week", this.formattedData(claimsThisWeek)) }
          { claimsThisMonth.length > 0 && this.renderChart("Claims This Month", this.formattedData(claimsThisMonth)) }
          { claimsThisYear.length > 0 && this.renderChart("Claims This Year", this.formattedData(claimsThisYear, justMonth)) }
          </div>
          <div style={{ display: 'inline-block', textAlign: 'center' }}>
          { sourcesThisMonth.length > 0 && this.renderChart("Sources This Month", this.formattedNoDateData(sourcesThisMonth), true) }
          { sourcesAllTime.length > 0 && this.renderChart("Sources All-Time", this.formattedNoDateData(sourcesAllTime), true) }
          </div>
          <div style={{ display: 'inline-block', textAlign: 'center' }}>
          { sourcesThisMonth.length > 0 && this.renderChart("Owners This Month", this.formattedNoDateData(ownersThisMonth), true) }
          { sourcesAllTime.length > 0 && this.renderChart("Owners All-Time", this.formattedNoDateData(ownersAllTime), true) }
          </div>
        </div>
        <Form style={{ padding: '20px', maxWidth: '1000px' }}>
          <Form.Group controlId="formName">
            <Form.Label>Start Date</Form.Label>
            <Form.Control type="date" name='startDate' value={startDate} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group controlId="formFirmName">
            <Form.Label>End Date</Form.Label>
            <Form.Control type="date" name='endDate' value={endDate} onChange={this.handleChange} />
          </Form.Group>
          <div style={{ marginTop: '40px' }}>
            <Button variant="primary" onClick={() => { this.onSubmit() }}>
              Generate Report
            </Button>
          </div>
        </Form>
        {!noReports &&
          <Table
              key={reports[0].id}
              style={{ margin: '20px', maxWidth: '1000px' }}
          >
            <thead>
              <tr>
                <th>Created At</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Url</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => ( this.rowsForReport(report) ))}
            </tbody>
          </Table>
        }
      </div>
    );
  }
}

export default withRouter(Reports);
