import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import StarRating from './StarRating'
import { Form } from 'react-bootstrap'
import { GetReviews } from '../network/ApiManager'
import { withRouter } from 'react-router'
import { dateToDateTimeString } from '../utilities/DateFormatting'

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    const { userId } = this.props.match.params

    GetReviews(userId, (reviews) => {
      this.setState({ reviews })
    }, (error) => {
      alert(error)
    })
  }

  renderReview(review) {
    const { speedOfService, qualityOfReview, friendlinessOfLawyer, note, createdAt } = review
    const { senderDescriptor } = review.claim || {}

    return (
      <div>
        <Form style={{ padding: '20px', width: '800px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '40px' }}>
          <h2>Feedback for {dateToDateTimeString(createdAt)} {senderDescriptor || ''}</h2>
          <div style={{ height: '40px' }} />
          <Form.Group controlId="speedOfService">
            <Form.Label>Speed of service:</Form.Label>
            <StarRating
                readOnly
                name="speedOfService"
                value={speedOfService} />
          </Form.Group>
          <div style={{ height: '8px' }} />
          <Form.Group controlId="qualityOfReview">
            <Form.Label>Quality of review:</Form.Label>
            <StarRating
                readOnly
                name="qualityOfReview"
                value={qualityOfReview} />
          </Form.Group>
          <div style={{ height: '8px' }} />
          <Form.Group controlId="friendlinessOfLawyer">
            <Form.Label>Friendliness of lawyer:</Form.Label>
            <StarRating
                readOnly
                name="friendlinessOfLawyer"
                value={friendlinessOfLawyer}/>
          </Form.Group>
          <div style={{ height: '8px' }} />
          <Form.Group controlId="note">
            <Form.Label>Anything else you want to tell us?</Form.Label>
            <br/>
            <br/>
            <Form.Label>{note}</Form.Label>
          </Form.Group>
        </Form>
        <hr style={{ marginRight: 'auto', marginLeft: 'auto', width: '800px' }}/>
      </div>
    );
  }

  render() {
    const { reviews } = this.state

    if (reviews && reviews.length === 0) {
      return (<div style={{ padding: '20px' }}><h2>No feedback</h2></div>)
    }

    return (
      <div>
        {(reviews || []).map((review) => ( this.renderReview(review) ))}
      </div>
    );
  }
}

export default withRouter(Reviews);
