import 'bootstrap/dist/css/bootstrap.min.css';
import './style/shared.css';
import React from 'react'
import { Alert, Button } from 'react-bootstrap';

class PopupAlert extends React.Component {
  render() {
    const { title, body, selectedOption } = this.props;
    return (
      <div style={{
          position: 'absolute',
          left: '0',
          right: '0',
          top: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
        onClick={() => { selectedOption(0) }}
      >
        <Alert
          show
          variant="primary"
          style={{
            maxWidth: '600px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Alert.Heading>{title}</Alert.Heading>
          <p>{body}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button variant="danger" onClick={() => { selectedOption(0) }}>
              No
            </Button>
            <div style={{ width: '10px' }}/>
            <Button variant="success" onClick={() => { selectedOption(1) }}>
              Yes
            </Button>
          </div>
        </Alert>
      </div>
    );
  }
}

export default PopupAlert;
