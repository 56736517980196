import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import { Form, Button } from 'react-bootstrap';
import { GetMe } from '../network/ApiManager'
import { withRouter } from 'react-router'

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firmName: '',
      name: '',
      websiteUrl: '',
      phoneNumber: '',
      averageRating: 0,
    }

    this.editProfile = this.editProfile.bind(this)
  }

  componentDidMount() {
    GetMe((user) => {
       this.setState({ ...user })
    }, (error) => {
        console.error(`Error Getting Profile: ${JSON.stringify(error)}`);
    })
  }

  editProfile() {
    this.props.history.push('/profile/edit')
  }

  render() {
    const { firmName, email, name, websiteUrl, phoneNumber, averageRating } = this.state;
    return (
      <Form style={{ padding: '20px' }}>
        <Form.Group controlId="formName">
          <Form.Label>Lawyer Name <strong>{name}</strong></Form.Label>
        </Form.Group>
        <Form.Group controlId="formFirmName">
          <Form.Label>Law Firm <strong>{firmName}</strong></Form.Label>
        </Form.Group>
        <Form.Group controlId="formFirmWebsite">
          <Form.Label>Law Firm Website <strong>{websiteUrl}</strong></Form.Label>
        </Form.Group>
        <Form.Group controlId="formFirmPhone">
          <Form.Label>Law Firm Contact <strong>{phoneNumber}</strong></Form.Label>
        </Form.Group>
        <Form.Group controlId="formFirmName">
          <Form.Label>Law Firm Email: <strong>{email}</strong></Form.Label>
        </Form.Group>
        <Form.Group controlId="formFirmName">
          <Form.Label>Average Star Rating: <strong>{`\u2605 ${averageRating.toFixed(2)}`}</strong></Form.Label>
        </Form.Group>
        <div style={{ marginTop: '40px' }}>
          <Button onClick={() => { this.editProfile() }}>
            Edit Information
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(Profile);
