import axios from 'axios'

const baseUrl = () => {
  //return "http://localhost:5000"
  return "https://api.legalreview.ca"
}

const headers = (method = 'GET') => {
  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  const token = localStorage.getItem('accessToken');
  if (token) {
    headers = {...headers, 'Authorization': `Bearer ${token}` }
  }

  return headers;
}

axios.interceptors.request.use(function (config) {
  config.headers = headers()

  return config;
});

export const Authenticate = (email, password, success, error) => {
  axios.post(baseUrl() + "/auth/", { email, password }).then((response) => {
    const token = response.data.id_token
    const isAdmin = response.data.isAdmin || false
    if (token) {
      localStorage.setItem('accessToken', token);
      localStorage.setItem('isAdmin', isAdmin);
      success(token);
    }
  })
  .catch((e) => {
      error(e);
  })
}

export const GetReports = (success, error) => {
  axios.get(baseUrl() + "/reports/", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const DeleteReport = (id, success, error) => {
  axios.delete(baseUrl() + `/reports/${id}`, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const GetPrecedents = (success, error) => {
  axios.get(baseUrl() + "/precedents/", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const CreateReport = (startDate, endDate, success, error) => {
  axios.post(baseUrl() + "/reports/", { startDate, endDate }).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const DeletePrecedent = (id, success, error) => {
  axios.delete(baseUrl() + `/precedents/${id}`, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const PostPrecedentMultipart = (formData, success, error) => {
  axios.post(baseUrl() + "/precedents/", formData).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const GetTodayClaims = (success, error) => {
  axios.get(baseUrl() + "/claims/today", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const ClaimFile = (claimId, success, error) => {
  const now = (new Date()).getTime() / 1000.0;
  axios.put(baseUrl() + "/claims/" + claimId, { timeClaimed: now }, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const PutClaim = (claimId, claim, success, error) => {
  axios.put(baseUrl() + "/claims/" + claimId, claim, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const PostClaim = (claim, success, error) => {
  axios.post(baseUrl() + "/claims/", claim, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const ChangePassword = (payload, success, error) => {
  axios.post(baseUrl() + "/auth/set-password", payload, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const PostReview = (review, claimId, success, error) => {
  axios.post(baseUrl() + "/claims/" + claimId + "/review", review, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const GetReviews = (userId, success, error) => {
  axios.get(baseUrl() + "/users/" + userId + "/reviews", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const GetReview = (claimId, success, error) => {
  axios.get(baseUrl() + "/claims/" + claimId + "/review", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const PostClaimMultipart = (formData, success, error) => {
  axios.post(baseUrl() + "/claims/upload", formData).then((response) => {
    success(response.data);
  })
  .catch((e) => {
    error(e);
  })
}

export const PutProfile = (userId, user, success, error) => {
  axios.put(baseUrl() + "/users/" + userId, user, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const GetMe = (success, error) => {
  axios.get(baseUrl() + "/users/me", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const GetUser = (userId, success, error) => {
  axios.get(baseUrl() + "/users/" + userId, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const GetUsers = (success, error) => {
  axios.get(baseUrl() + "/users/", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const ResetPassword = (success, error) => {
  axios.post(baseUrl() + "/auth/reset-password", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const ChangeEmail = (payload, success, error) => {
  axios.post(baseUrl() + "/auth/change-email", payload, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const DeleteUser = (id, success, error) => {
  axios.delete(baseUrl() + `/users/${id}`, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const CreateUser = (user, success, error) => {
  axios.post(baseUrl() + "/users/", user, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const ResetPasswordNotLoggedIn = (email, success, error) => {
  axios.post(baseUrl() + "/auth/not-logged-in-reset-password", { email }, headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const GetArchivedClaims = (success, error) => {
  //TODO: paging
  axios.get(baseUrl() + "/claims?size=1000&page=0", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}

export const GetClaimStats = (success, error) => {
  axios.get(baseUrl() + "/claims/stats", headers()).then((response) => {
    success(response.data);
  })
  .catch((e) => {
      error(e);
  })
}
