import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import StarRating from './StarRating'
import { Form, Button } from 'react-bootstrap'
import { PostReview } from '../network/ApiManager'
import { withRouter } from 'react-router'

class CreateReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speedOfService: 5,
      qualityOfReview: 5,
      friendlinessOfLawyer: 5,
      note: '',
      reviewFinished: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { speedOfService, qualityOfReview, friendlinessOfLawyer, note } = this.state;

    const review = {
      note,
      speedOfService: speedOfService,
      qualityOfReview: qualityOfReview,
      friendlinessOfLawyer: friendlinessOfLawyer
    }

    PostReview(review, this.props.claimId, (review) => {
      this.setState({ reviewFinished: true })
    }, (error) => {
      alert(error)
    })
  }

  handleChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({ [name]: value })
  }

  render() {
    const { speedOfService, qualityOfReview, friendlinessOfLawyer, note, reviewFinished } = this.state

    if (reviewFinished) {
      return (<div style={{ padding: '20px', width: '400px', marginLeft: 'auto', marginRight: 'auto' }}><h1>Thank you!</h1></div>)
    }

    return (
      <Form style={{ padding: '20px', width: '800px', marginLeft: 'auto', marginRight: 'auto' }}>
        <h2>Rate your experience with Legal Review</h2>
        <p style={{ paddingBottom: '40px' }}>
          Thank you for your recent use of the Legal Review referral network. At Legal Review we strive to continually improve our service levels. Taking a minute to answer these questions allows us to continually better yours and other people's future experience with our site. We sincerely thank you for taking the time to rate your recent transaction.
        </p>
        <Form.Group controlId="speedOfService">
          <Form.Label>Speed of service:</Form.Label>
          <StarRating
              name="speedOfService"
              value={speedOfService}
              onChange={this.handleChange} />
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="qualityOfReview">
          <Form.Label>Quality of review:</Form.Label>
          <StarRating
              name="qualityOfReview"
              value={qualityOfReview}
              onChange={this.handleChange} />
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="friendlinessOfLawyer">
          <Form.Label>Friendliness of lawyer:</Form.Label>
          <StarRating
              name="friendlinessOfLawyer"
              value={friendlinessOfLawyer}
              onChange={this.handleChange} />
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="note">
          <Form.Label>Anything else you want to tell us?</Form.Label>
          <Form.Control as="textarea" type="textarea" name="note" value={note} onChange={this.handleChange} />
        </Form.Group>
        <div style={{ marginTop: '40px' }}>
          <Button style={{ marginLeft: '10px' }} variant="primary" onClick={() => { this.onSubmit() }}>
            Submit
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(CreateReview);
