import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import { Form, Button } from 'react-bootstrap';
import { PostPrecedentMultipart } from '../network/ApiManager'
import { withRouter } from 'react-router';

class CreatePrecedent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      url: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { isAdmin } = this.props

    if (isAdmin) {
      const formData = new FormData()
      formData.append("type", this.state.type)

      if (this.state.filepath) {
        formData.append("file", this.state.filepath)
      }
      if (this.state.url) {
        formData.append("url", this.state.url)
      }

      PostPrecedentMultipart(formData, (precedent) => {
        this.props.history.push('/precedents')
        alert('Precedent file created.')
      }, (error) => {
        alert(error)
      })
    }
    else {
      alert('Only admins can create precedents.')
    }
  }

  handleChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'file') {
      this.setState({ filepath: event.target.files[0], file: value })
    } else {
      this.setState({ [name]: value })
    }
  }

  render() {
    const { type, file, url } = this.state;
    const fileName = file ? file.split("\\").pop() : "Precedent file"

    return (
      <Form style={{ padding: '20px' }}>
        <h2>Create Precedent</h2>
        <Form.Group controlId="type">
          <Form.Label>Precedent Type</Form.Label>
          <Form.Control type="input" name="type" value={type} onChange={this.handleChange} />
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="url">
          <Form.Label>Precedent File Url</Form.Label>
          <Form.Control type="input" name="url" value={url} onChange={this.handleChange} />
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="file">
          <Form.Label>Precedent File</Form.Label>
          <Form.File
            id="file"
            label={fileName}
            name="file"
            custom
            onChange={this.handleChange}
          />
        </Form.Group>
        <div style={{ marginTop: '40px' }}>
          <Button style={{ marginLeft: '10px' }} variant="primary" onClick={() => { this.onSubmit() }}>
            Submit
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(CreatePrecedent);
