import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import { Form, Button } from 'react-bootstrap';
import { PutClaim, GetUsers } from '../network/ApiManager'
import { toServerFormat, enumToPresentableString } from '../utilities/StringUtils'

class EditClaim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      ...this.props.claim,
      ownership: this.props.claim.owner ? this.props.claim.owner.email : null,
      retained: this.props.claim.retained || 'Pending',
      timeReceived: this.props.claim.timeReceived ? new Date(1000 * this.props.claim.timeReceived).toISOString() : null,
      timeClaimed: this.props.claim.timeClaimed ? new Date(1000 * this.props.claim.timeClaimed).toISOString() : null,
      responseTime: this.props.claim.responseTime ? new Date(1000 * this.props.claim.responseTime).toISOString() : null,
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(didSave) {
    const { isAdmin } = this.props
    const dateStringToDate = (dateString) => { return dateString ? ((new Date(dateString)).getTime() / 1000.0) : null }

    if (!didSave) {
      this.props.didUpdateClaim(null)
    }
    else {
      const { requestType, timeReceived, clientType, timeClaimed, responseTime, ownership, pdfUrl, lawyerNotes, adminNotes, retained } = this.state;
      if (isAdmin) {
        let body = {
          requestType: toServerFormat(requestType),
          clientType: toServerFormat(clientType),
          timeReceived: dateStringToDate(timeReceived),
          timeClaimed: dateStringToDate(timeClaimed),
          timeResponded: dateStringToDate(responseTime),
          pdfUrl,
          lawyerNotes,
          adminNotes,
          fileRetainedStatus: toServerFormat(retained)
        }

        const owner = this.state.users.filter((u) => u.email === ownership)[0]
        if (owner) {
          body = {...body, owner}
        }

        PutClaim(this.state.id, body, (claim) => {
          this.props.didUpdateClaim(claim)
        }, (error) => {
          alert(error)
        })
      }
      else {
        PutClaim(this.state.id, { fileRetainedStatus: toServerFormat(retained), lawyerNotes }, (claim) => {
          this.props.didUpdateClaim(claim)
        }, (error) => {
          alert(error)
        })
      }
    }
  }

  componentDidMount() {
    GetUsers((users) => {
       this.setState({ users })
    }, (error) => {
        console.error(`Error Getting Profile: ${JSON.stringify(error)}`);
    })
  }

  handleChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  render() {
    const { isAdmin } = this.props
    const { users } = this.state
    const { requestType, timeReceived, clientType, timeClaimed, responseTime, ownership, pdfUrl, lawyerNotes, adminNotes, retained } = this.state;
    return (
      <Form style={{ padding: '20px' }}>
        <h2>Edit Claim</h2>
        {isAdmin && <Form.Group controlId="formType">
          <Form.Label>Type</Form.Label>
          <Form.Control as="select" name="requestType" value={enumToPresentableString(requestType)} onChange={this.handleChange}>
            <option key="status-review">Status Review Requested</option>
            <option key="new-build-agreement">New Build Agreement Review Requested</option>
          </Form.Control>
        </Form.Group>}
        {isAdmin && <Form.Group controlId="formTimeReceived">
          <Form.Label>Time Received</Form.Label>
          <Form.Control type="input" name="timeReceived" value={timeReceived || ''} onChange={this.handleChange} />
        </Form.Group>}
        {isAdmin && <Form.Group controlId="formClientType">
          <Form.Label>Client Type</Form.Label>
          <Form.Control as="select" name="clientType" value={enumToPresentableString(clientType)} onChange={this.handleChange}>
            <option key="agent">Agent</option>
            <option key="individual">Individual</option>
          </Form.Control>
        </Form.Group>}
        {isAdmin && <Form.Group controlId="formTimeClaimed">
          <Form.Label>Time Claimed</Form.Label>
          <Form.Control type="input" name="timeClaimed" value={timeClaimed || ''} onChange={this.handleChange} />
        </Form.Group>}
        {isAdmin && <Form.Group controlId="formResponseTime">
          <Form.Label>Response Time</Form.Label>
          <Form.Control type="input" name="responseTime" value={responseTime || ''} onChange={this.handleChange} />
        </Form.Group>}
        {isAdmin && <Form.Group controlId="formOwnership">
          <Form.Label>Ownership</Form.Label>
          <Form.Control as="select" name="ownership" value={ownership} onChange={this.handleChange}>
            {users.map((user) => (<option key={user.id}>{user.email}</option>))}
          </Form.Control>
        </Form.Group>}
        {isAdmin && <Form.Group controlId="formPdfUrl">
          <Form.Label>Document Url:</Form.Label>
          <Form.Control type="input" name="pdfUrl" value={pdfUrl || ''} onChange={this.handleChange} />
        </Form.Group>}
        <Form.Group controlId="formLawyerNotes">
          <Form.Label>Lawyer Notes</Form.Label>
          <Form.Control type="input" name="lawyerNotes" value={lawyerNotes || ''} onChange={this.handleChange} />
        </Form.Group>
        {isAdmin && <Form.Group controlId="formAdminNotes">
          <Form.Label>Admin Notes</Form.Label>
          <Form.Control type="input" name="adminNotes" value={adminNotes || ''} onChange={this.handleChange} />
        </Form.Group>}
        <Form.Group controlId="formRetained">
          <Form.Label>Retained</Form.Label>
          <Form.Control as="select" name="retained" value={enumToPresentableString(retained)} onChange={this.handleChange}>
            <option key="retained">Retained</option>
            <option key="not-retained">Not Retained</option>
            <option key="pending">Pending</option>
          </Form.Control>
        </Form.Group>
        <div style={{ marginTop: '40px' }}>
          <Button variant="danger" onClick={() => { this.onSubmit(false) }}>
            Cancel
          </Button>
          <Button style={{ marginLeft: '10px' }} variant="primary" onClick={() => { this.onSubmit(true) }}>
            Submit
          </Button>
        </div>
      </Form>
    );
  }
}

export default EditClaim;
