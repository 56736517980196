import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import StarRating from './StarRating'
import { Form } from 'react-bootstrap'
import { GetReview } from '../network/ApiManager'
import { withRouter } from 'react-router'

class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      speedOfService: 0,
      qualityOfReview: 0,
      friendlinessOfLawyer: 0,
      claim: {},
      note: '',
    }
  }

  componentDidMount() {
    const { claimId } = this.props.match.params

    GetReview(claimId, (review) => {
      this.setState({ ...review })
    }, (error) => {
      alert(error)
    })
  }

  render() {
    const { speedOfService, qualityOfReview, friendlinessOfLawyer, note, claim } = this.state
    const { senderDescriptor } = claim || {}

    return (
      <Form style={{ padding: '20px', width: '800px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '40px' }}>
        <h2>Feedback</h2>
        <strong>{senderDescriptor || ''}</strong>
        <div style={{ height: '20px' }}/>
        <Form.Group controlId="speedOfService">
          <Form.Label>Speed of service:</Form.Label>
          <StarRating
              readOnly
              name="speedOfService"
              value={speedOfService} />
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="qualityOfReview">
          <Form.Label>Quality of review:</Form.Label>
          <StarRating
              readOnly
              name="qualityOfReview"
              value={qualityOfReview} />
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="friendlinessOfLawyer">
          <Form.Label>Friendliness of lawyer:</Form.Label>
          <StarRating
              readOnly
              name="friendlinessOfLawyer"
              value={friendlinessOfLawyer}/>
        </Form.Group>
        <div style={{ height: '8px' }} />
        <Form.Group controlId="note">
          <Form.Label>Anything else you want to tell us?</Form.Label>
          <br/>
          <br/>
          <Form.Label>{note}</Form.Label>
        </Form.Group>
      </Form>
    );
  }
}

export default withRouter(Review);
