import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { ChangeEmail, Authenticate } from '../network/ApiManager'
import { withRouter } from 'react-router';
import { validateEmail, getSignedInEmail } from '../utilities/StringUtils'

class ChangeEmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      oldPassword: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  onSubmit() {
    const { email, password, oldPassword, confirmPassword } = this.state;

    if (!validateEmail(email)) {
      alert('Invalid email.')
      this.setState({ email: '', password: '', confirmPassword: '', oldPassword: '' })
      return
    }
    if (password.length < 8 || oldPassword.length < 8 || confirmPassword.length < 8) {
      alert('Passwords must be at least 8 characters in length.')
      this.setState({ password: '', confirmPassword: '', oldPassword: '' })
      return
    }
    if (confirmPassword !== password) {
      alert('Passwords do not match.')
      this.setState({ password: '', confirmPassword: '', oldPassword: '' })
      return
    }

    const oldEmail = getSignedInEmail()
    if (!oldEmail) {
      alert('There was a problem updating your email.')
      return
    }

    Authenticate(oldEmail, oldPassword, () => {
      ChangeEmail({ email, password }, (user) => {
        this.props.onChangeEmail()
      }, (error) => {
        alert(error)
      })
    }, (error) => {
      alert(error)
    });
  }

  render() {
    const { email, password, oldPassword, confirmPassword } = this.state;
    return (
      <Form style={{ padding: '20px' }}>
        <Form.Group controlId="formFirmEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="The new email you'd like to use" name="email" value={email} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmPassword">
          <Form.Label>Old Password</Form.Label>
          <Form.Control type="password" placeholder="Enter new password" name="oldPassword" value={oldPassword} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control type="password" placeholder="Enter new password" name="password" value={password} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmPassword">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control type="password" placeholder="Confirm new password" name="confirmPassword" value={confirmPassword} onChange={this.handleChange} />
        </Form.Group>
        <div style={{ marginTop: '40px' }}>
          <Button variant="primary" onClick={() => { this.onSubmit() }}>
            Change Email
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(ChangeEmailForm);
