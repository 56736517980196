import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import Claims from './components/Claims'
import ArchivedClaims from './components/ArchivedClaims'
import Profile from './components/Profile'
import EditProfile from './components/EditProfile'
import SignIn from './components/SignIn'
import AddUser from './components/AddUser'
import EditUser from './components/EditUser'
import Reports from './components/Reports'
import CreateClaim from './components/CreateClaim'
import CreateReview from './components/CreateReview'
import Review from './components/Review'
import Reviews from './components/Reviews'
import Users from './components/Users'
import Claim from './components/Claim'
import CreatePrecedent from './components/CreatePrecedent'
import Precedents from './components/Precedents'
import ChangeEmailForm from './components/ChangeEmailForm'
import Logo from './assets/logo.png'
import { withRouter } from 'react-router'

class App extends React.Component {
  constructor(props) {
    super(props);

    const isAdmin = (localStorage.getItem('isAdmin') === "true");
    const accessToken = localStorage.getItem('accessToken');

    this.state = {
      isAdmin,
      accessToken,
    }
    this.didSignOut = this.didSignOut.bind(this)
    this.didSignIn = this.didSignIn.bind(this)
  }

  didSignIn(accessToken) {
    const isAdmin = (localStorage.getItem('isAdmin') === "true");
    this.setState({ accessToken: accessToken, isAdmin });
  }

  didSignOut() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('isAdmin');

    this.setState({ accessToken: null, isAdmin: false });
  }

  render() {
    const { accessToken, isAdmin, apiManager } = this.state;

    if (this.props.location.pathname === '/review') {
      const urlParams = new URLSearchParams(this.props.location.search)
      const claimId = urlParams.get('claimId')

      return (<CreateReview apiManager={apiManager} claimId={claimId}/>)
    }

    if (!accessToken) {
      return (
        <SignIn
          apiManager={apiManager}
          onSignIn={this.didSignIn}
        />
      )
    }

    return (
      <>
        <Navbar
            bg="light"
            expand="lg"
            style={{
              overflowX: 'hidden',
            }}
        >
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={Logo}
              style={{
                width: '28px',
                height: '28px',
                float: 'left',
              }}
            />
            <div style={{ float: 'left', marginLeft: '10px' }}>Legal Review</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">{isAdmin ? 'Claims' : 'Open Claims'}</Nav.Link>
              {!isAdmin && <Nav.Link href="/archived">Historical Claims</Nav.Link>}
              {isAdmin && <Nav.Link href="/users">Users</Nav.Link>}
              <Nav.Link href="/profile">Profile</Nav.Link>
              <Nav.Link href="/precedents">Precedents</Nav.Link>
              {isAdmin && <Nav.Link href="/reports">Reports</Nav.Link>}
              <Nav.Link href="/" onClick={this.didSignOut}>Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div
          style={{
            overflowX: 'scroll',
          }}
        >
          <Switch>
            <Route exact path="/">
              <Claims apiManager={apiManager} isAdmin={isAdmin} />
            </Route>
            <Route exact path="/archived">
              <ArchivedClaims isAdmin={isAdmin} />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/profile/edit">
              <EditProfile />
            </Route>
            <Route exact path="/reports">
              <Reports />
            </Route>
            <Route exact path="/adduser">
              <AddUser />
            </Route>
            <Route exact path="/users">
              <Users />
            </Route>
            <Route exact path="/users/:userId/reviews">
              <Reviews />
            </Route>
            <Route exact path="/users/:userId">
              <EditUser />
            </Route>
            <Route exact path="/precedents">
              <Precedents isAdmin={isAdmin}/>
            </Route>
            <Route exact path="/precedents/add">
              <CreatePrecedent isAdmin={isAdmin}/>
            </Route>
            <Route exact path="/users/change-emails">
              <ChangeEmailForm onChangeEmail={ () => { this.didSignOut(); this.props.history.push('') } }/>
            </Route>
            <Route exact path="/claims/create">
              <CreateClaim isAdmin={isAdmin} />
            </Route>
            <Route exact path="/claim/:claimId/review">
              <Review />
            </Route>
            <Route exact path="/claim/:id">
              <Claim />
            </Route>
            <Route render={function () {
              return <p>Not found</p>
            }} />
          </Switch>
        </div>
      </>
    );
  }
}

export default withRouter(App);
