import 'bootstrap/dist/css/bootstrap.min.css';
import './style/shared.css';
import React from 'react'
import { PutClaim } from '../network/ApiManager'
import { withRouter } from 'react-router';
import queryString from 'query-string';

class Claim extends React.Component {
  componentDidMount() {
    const { id } = this.props.match.params
    const params = queryString.parse(this.props.location.search)
    const didRetain = params['didRetain']

    if (didRetain) {
      const value = didRetain === 'true' || didRetain === 'yes' ? 'RETAINED' : 'NOT_RETAINED';
      PutClaim(id, { fileRetainedStatus: value }, (updatedClaim) => {
        this.props.history.push('/')
        alert(didRetain === 'true' || didRetain === 'yes' ? 'Your file was marked as retained.' : 'Your file was marked as not retained.')
      }, (error) => {
        alert(error)
        this.props.history.push('/')
      })
    }
    else {
      this.props.history.push('/')
    }
  }

  render() {
    return (
      <div/>
    );
  }
}

export default withRouter(Claim);
