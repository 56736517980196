import { dataDateFormatString } from './DateFormatting.js'

export function bucketClaimsByDay(claims) {
  let claimsByDay = {}

  for (var i1 = 0;i1 < claims.length;i1 += 1) {
    const claim = claims[i1]
    const dateKey = dataDateFormatString(claim["timeReceived"])
    if (claimsByDay[dateKey]) {
      claimsByDay[dateKey].push(claim)
    }
    else {
      claimsByDay[dateKey] = [ claim ]
    }
  }

  const dates = Object.keys(claimsByDay).sort().reverse()
  let buckets = []
  for (var i2 = 0;i2 < dates.length;i2 += 1) {
    buckets.push(claimsByDay[dates[i2]])
  }

  return buckets
}
