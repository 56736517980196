import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import { Form, Button } from 'react-bootstrap';
import { PutProfile, GetUser } from '../network/ApiManager'
import { withRouter } from 'react-router'

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firmName: '',
      name: '',
      websiteUrl: '',
      phoneNumber: '',
      notificationDelay: 0,
      isAdmin: false,
      receiveNotifications: true
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    const { userId } = this.props.match.params
    GetUser(userId ,(user) => {
       this.setState({ ...user })
    }, (error) => {
        console.error(`Error Getting Profile: ${JSON.stringify(error)}`);
    })
  }

  changeEmail() {
    this.props.history.push('/users/change-emails')
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  onSubmit() {
    const {
      firmName,
      name,
      websiteUrl,
      phoneNumber,
      receiveNotifications,
      notificationDelay,
      isAdmin
    } = this.state;
    const isCurrentUserAdmin = (localStorage.getItem('isAdmin') === "true");
    let payload = {
      name,
      firmName,
      websiteUrl,
      phoneNumber,
      receiveNotifications,
      isAdmin
    }
    if (isCurrentUserAdmin) {
      payload = {...payload, notificationDelay}
    }

    PutProfile(this.state.id, payload, (user) => {
      this.props.history.push('/users')
    }, (error) => {
      alert(error)
    })
  }

  render() {
    const {
      firmName,
      email,
      name,
      websiteUrl,
      phoneNumber,
      receiveNotifications,
      notificationDelay,
      isAdmin
    } = this.state;
    const isCurrentUserAdmin = (localStorage.getItem('isAdmin') === "true");
    return (
      <Form style={{ padding: '20px' }}>
        <Form.Group controlId="formFirmName">
          <Form.Label>Email: <strong>{email}</strong></Form.Label>
        </Form.Group>
        <Form.Group controlId="formName">
          <Form.Label>Lawyer Name</Form.Label>
          <Form.Control type="input" placeholder="Enter your name" name="name" value={name} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmName">
          <Form.Label>Law Firm Name</Form.Label>
          <Form.Control type="input" placeholder="Enter your firm's name" name="firmName" value={firmName} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmWebsite">
          <Form.Label>Law Firm Website</Form.Label>
          <Form.Control type="input" placeholder="Enter your firm's website url" name="websiteUrl" value={websiteUrl} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmPhone">
          <Form.Label>Law Firm Contact Phone Number</Form.Label>
          <Form.Control type="input" placeholder="Enter your firm's contact phone number" name="phoneNumber" value={phoneNumber} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmNotifications">
          <Form.Label>Notifications</Form.Label>
          <Form.Check type="checkbox" label="Receive notifications" name="receiveNotifications" checked={receiveNotifications} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmNotifications">
          <Form.Label>Is Admin</Form.Label>
          <Form.Check type="checkbox" label="Is Admin" name="isAdmin" checked={isAdmin} onChange={this.handleChange} />
        </Form.Group>
        {isCurrentUserAdmin &&
        <Form.Group controlId="formNotificationDelay">
          <Form.Label>Notification Delay (minutes)</Form.Label>
          <Form.Check type="input" label="Notification Delay" name="notificationDelay" value={notificationDelay} onChange={this.handleChange} />
        </Form.Group>
        }
        <div style={{ marginTop: '40px' }}>
          <Button variant="primary" onClick={() => { this.onSubmit() }}>
            Edit
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(EditUser);
