import 'bootstrap/dist/css/bootstrap.min.css';
import './style/shared.css';
import React from 'react'
import { Alert, Form, Button } from 'react-bootstrap'

class ChangePasswordPopupAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: '', confirmPassword: '', error: null }

    this.handleChange = this.handleChange.bind(this)
  }

  validateAndChangePassword() {
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      this.setState({ error: 'Passwords do not match.' })
    }
    else if (password.length < 8) {
      this.setState({ error: 'Passwords must be 8 or more characters in length.' })
    }
    else {
      this.props.changePassword(true, password)
      this.setState({ error: null, password: '', confirmPassword: '' })
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  render() {
    const { user } = this.props;
    const { password, confirmPassword, error } = this.state;
    return (
      <div style={{
          position: 'absolute',
          left: '0',
          right: '0',
          top: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
        onClick={() => { this.props.changePassword(false, '') }}
      >
        <Alert
          show
          variant="primary"
          onClick={(e) => { e.stopPropagation(); e.nativeEvent.stopImmediatePropagation(); }}
          style={{
            maxWidth: '600px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Alert.Heading>Change Password</Alert.Heading>
          {error && (<p style={{ color: 'red' }}>{error}</p>)}
          <p>{`Change password for ${user.email}.`}</p>
          <hr />
          <Form style={{ padding: '20px' }}>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" value={password} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" name="confirmPassword" value={confirmPassword} onChange={this.handleChange} />
            </Form.Group>
            <div style={{ marginTop: '40px' }}>
              <Button style={{ marginLeft: '10px' }} variant="primary" onClick={() => { this.validateAndChangePassword() }}>
                Change Password
              </Button>
            </div>
          </Form>
        </Alert>
      </div>
    );
  }
}

export default ChangePasswordPopupAlert;
