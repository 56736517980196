import React from 'react'

const Star = ({ marked, index }) => {
  return (
    <span style={{ color: "#FDE16D", fontSize: "45px" }} star-id={index}>
      {marked ? '\u2605' : '\u2606'}
    </span>
  );
};

class StarRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: 5,
    }
  }

  componentDidMount() {
    const { value, readOnly } = this.props
    if (readOnly) {
      this.setState({ selection: value })
    }
  }

  render() {
    const { value, onChange, name } = this.props
    const { selection } = this.state

    const hoverOver = event => {
      if (this.props.readOnly === true) {
        // do nothing
      } else {
        if (event && event.target && event.target.getAttribute('star-id')) {
          const val = event.target.getAttribute('star-id')
          this.setState({ selection: val })
          onChange({ target: { name, type: "StarRating", value: val}})
        }
      }
    }

    return (
      <div
        onMouseOut={() => hoverOver(null)}
        onClick={e => hoverOver(e)}
        onMouseOver={hoverOver}
      >
        {Array.from({ length: 5 }, (v, i) => (
          <Star
            index={i + 1}
            key={`star_rating_${i + 1}`}
            marked={selection ? selection >= i + 1 : value >= i + 1}
          />
        ))}
      </div>
    )
  }
}

export default StarRating;
