import 'bootstrap/dist/css/bootstrap.min.css';
import './style/shared.css';
import React from 'react'
import { dateToDateTimeString } from '../utilities/DateFormatting.js'
import { Table, Button } from 'react-bootstrap';
import { GetPrecedents, DeletePrecedent } from '../network/ApiManager'
import { withRouter } from 'react-router';

class Precedents extends React.Component {
  constructor(props) {
    super(props);
    this.state = { precedents: [] }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.deletePrecedent = this.deletePrecedent.bind(this);
    this.rowsForPrecedent = this.rowsForPrecedent.bind(this);
    this.refreshPrecedentsList = this.refreshPrecedentsList.bind(this);
  }

  refreshPrecedentsList() {
    GetPrecedents((precedents) => {
       this.setState({ precedents })
    }, (error) => {
        console.error(`${JSON.stringify(error)}`);
    })
  }

  componentDidMount() {
    this.refreshPrecedentsList()
  }

  deletePrecedent(precedent) {
    DeletePrecedent(precedent.id, () => {
       this.refreshPrecedentsList()
    }, (error) => {
        console.error(`${JSON.stringify(error)}`);
    })
  }

  linkToPrecedent(precedent) {
    if (precedent.documentUrl) {
      const isS3 = precedent.documentUrl.includes(".s3.us-east-2.amazonaws.com")
      const name = isS3 ? precedent.documentUrl.split('/').pop() : precedent.documentUrl
      return (<a target="_blank" rel="noreferrer" href={`${precedent.documentUrl}`}>{name}</a>)
    }
    else {
      return (<div/>)
    }
  }

  rowsForPrecedent(precedent) {
    const { isAdmin } = this.props;

    const renderActionCell = () => {
      if (isAdmin) {
        return (<td>
          <Button
            onClick={ () => {
                this.deletePrecedent(precedent)
            }}
            variant="primary"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            Delete
          </Button>
        </td>)
      }
      else {
        return (<td></td>)
      }
    }

    return (
        <tr
          key={precedent.id}
          style={{ backgroundColor: '#E7E6E6' }}
        >
          {isAdmin && renderActionCell()}
          <td>{precedent.type}</td>
          <td>{dateToDateTimeString(precedent.createdAt)}</td>
          <td style={{ maxWidth: '400px', overflow: 'hidden' }} >{this.linkToPrecedent(precedent)}</td>
        </tr>
    );
  }

  render() {
    const precedents = this.state.precedents;
    const { isAdmin } = this.props;

    const noPrecedents = precedents.length === 0

    return (
      <div style={{ padding: '20px' }}>
        {isAdmin &&
          <div style={{ marginBottom: '40px', marginTop: '20px' }}>
            <Button style={{ marginLeft: '0px' }} variant="primary" onClick={() => { this.props.history.push('/precedents/add') }}>
              Add Precedent
            </Button>
          </div>
        }
        {noPrecedents && <div>No Precedent Files</div>}
        {!noPrecedents &&
          <Table
              key={precedents[0].id}
              style={{ marginBottom: -1 }}
          >
            <thead>
              <tr>
                {isAdmin && <th/>}
                <th>Precedent Type</th>
                <th>Upload Date</th>
                <th>File</th>
              </tr>
            </thead>
            <tbody>
              {precedents.map((precedent) => ( this.rowsForPrecedent(precedent) ))}
            </tbody>
          </Table>
        }
        </div>
      );
  }
}

export default withRouter(Precedents);
