import DateFormat from 'dateformat';

export function dateToDateTimeString(date) {
  if (!date) {
    return '';
  }
  return DateFormat(date * 1000, "mmmm dS, yyyy, h:MM TT");
}

export function dateToDateString(date) {
  if (!date) {
    return '';
  }
  return DateFormat(date * 1000, "mmmm dS, yyyy");
}

export function formDateStringFormat(date) {
  if (!date) {
    return '';
  }
  return DateFormat(date, "yyyy-mm-dd");
}

export function justMonth(date) {
  if (!date) {
    return '';
  }
  return DateFormat(date * 1000, "mmm");
}

export function monthAndDate(date) {
  if (!date) {
    return '';
  }
  return DateFormat(date * 1000, "mmm d");
}

export function dataDateFormatString(date) {
  if (!date) {
    return '';
  }
  return DateFormat(date * 1000, "yyyy/mm/dd");
}
