import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { CreateUser } from '../network/ApiManager'
import { withRouter } from 'react-router';

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firmName: '',
      website: '',
      phone: '',
      name: '',
      email: '',
      password: '',
      notifications: true,
      isAdmin: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  onSubmit() {
    const { firmName, name, isAdmin, website, phone, email, notifications, password } = this.state;

    if (password.length < 8) {
      alert('Password must be 8 or more characters.')
      return
    }

    CreateUser({ firmName, name, websiteUrl: website, phoneNumber: phone, email, receiveNotifications: notifications, isAdmin, password }, (user) => {
      this.props.history.push('/users')
    }, (error) => {
      alert(error)
    })
  }

  render() {
    const { firmName, name, website, phone, email, notifications, isAdmin, password } = this.state;
    return (
      <Form style={{ padding: '20px' }}>
        <Form.Group controlId="formName">
          <Form.Label>Lawyer Name</Form.Label>
          <Form.Control type="input" placeholder="Enter your name" name="name" value={name} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmName">
          <Form.Label>Law Firm Name</Form.Label>
          <Form.Control type="input" placeholder="Enter your firm's name" name="firmName" value={firmName} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmWebsite">
          <Form.Label>Law Firm Website</Form.Label>
          <Form.Control type="input" placeholder="Enter your firm's website url" name="website" value={website} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmPhone">
          <Form.Label>Law Firm Contact Phone Number</Form.Label>
          <Form.Control type="input" placeholder="Enter your firm's contact phone number" name="phone" value={phone} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmEmail">
          <Form.Label>Law Firm Contact Email</Form.Label>
          <Form.Control type="email" placeholder="Enter your firm's email" name="email" value={email} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Enter new account password" name="password" value={password} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formFirmNotifications">
          <Form.Label>Notifications</Form.Label>
          <Form.Check type="checkbox" label="Receive notifications" name="notifications" checked={notifications} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="formIsAdmin">
          <Form.Label>Is Administrator</Form.Label>
          <Form.Check type="checkbox" label="Is Administrator" name="isAdmin" checked={isAdmin} onChange={this.handleChange} />
        </Form.Group>
        <div style={{ marginTop: '40px' }}>
          <Button variant="primary" onClick={() => { this.onSubmit() }}>
            Add User
          </Button>
        </div>
      </Form>
    );
  }
}

export default withRouter(AddUser);
