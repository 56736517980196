export function titleCase(string) {
  string = string.toLowerCase()
  string = string.split(' ')

  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1).toLowerCase()
  }

  return string.join(' ')
}

export function enumToPresentableString(string) {
  if (!string) {
    return '';
  }
  return titleCase(string.replace(/_/g, " "))
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getSignedInEmail() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    const components = accessToken.split(".")
    if (components.length >= 2) {
      const payloadString = atob(components[1])
      const payload = JSON.parse(payloadString)

      return payload["email"]
    }
  }

  return accessToken;
}

export function userToPresentableName(user) {
  if (!user) {
    return '';
  }
  return user.email
}

export function toServerFormat(string) {
  if (!string) {
    return '';
  }
  return string.replace(/ /g, "_").toUpperCase()
}
