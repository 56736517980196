import 'bootstrap/dist/css/bootstrap.min.css'
import './style/shared.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Button } from 'react-bootstrap'
import { GetUsers, DeleteUser, ChangePassword, PutProfile } from '../network/ApiManager'
import PopupAlert from './PopupAlert.js'
import ChangePasswordPopupAlert from './ChangePasswordPopupAlert.js'
import { withRouter } from 'react-router'

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = { users: [] }
    this.deleteUser = this.deleteUser.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.refreshUserList = this.refreshUserList.bind(this)
    this.showDeleteUserPopup = this.showDeleteUserPopup.bind(this)
    this.showChangePasswordUserPopup = this.showChangePasswordUserPopup.bind(this)
    this.toggleAdditionalNotifications = this.toggleAdditionalNotifications.bind(this)
  }

  refreshUserList = () => {
    GetUsers((users) => {
      const sortedUsers = users.sort((a, b) => {
        if(a.email < b.email) { return -1 }
        if(a.email > b.email) { return 1 }

        return 0
      })
       this.setState({ users: sortedUsers })
    }, (error) => {
        console.error(`Error Getting Profile: ${JSON.stringify(error)}`);
    })
  }

  componentDidMount() {
    this.refreshUserList()
  }

  showDeleteUserPopup(user) {
    this.setState({ popupDeleteUser: user })
  }

  showChangePasswordUserPopup(user) {
    this.setState({ changePasswordUser: user })
  }

  deleteUser(user) {
    DeleteUser(user.id, () => {
      this.setState({ popupDeleteUser: undefined })
      this.refreshUserList()
    }, (error) => { alert(error) })
  }

  changePassword(user, password) {
    ChangePassword({ email: user.email, password }, () => {
      this.setState({ changePasswordUser: undefined, password: '', confirmPassword: '' })
      alert('Password changed.')
    }, (error) => { alert(error) })
  }

  editUser(user) {
    this.props.history.push(`/users/${user.id}`)
  }

  toggleAdditionalNotifications(user) {
    PutProfile(
      user.id,
      {...user, receiveAdditionalNotifications: !user.receiveAdditionalNotifications },
      () => {
        this.refreshUserList()
      },
      (error) => { alert(error) }
    )
  }

  render() {
    const { users, popupDeleteUser, changePasswordUser } = this.state;
    const popupText = popupDeleteUser ? (`Are you sure you want to ${popupDeleteUser.email} delete?`) : ''
    return (
      <div style={{ padding: '20px' }}>
        <Table>
          <thead>
            <tr>
              <th colSpan='12'>Users</th>
            </tr>
            <tr>
              <th>Firm Name</th>
              <th>Email</th>
              <th>Name</th>
              <th>Website</th>
              <th>Phone</th>
              <th>Admin</th>
              <th>Notifications</th>
              <th>admin-support</th>
              <th>Client Feedback</th>
              <th>Notification Delay</th>
              <th/>
              <th/>
              <th/>
            </tr>
          </thead>
          <tbody>
          {users.map((user) => (
            <tr
              key={user.id}
              style={{ backgroundColor: '#E7E6E6' }}
            >
              <td>{user.firmName}</td>
              <td>{user.email}</td>
              <td>{user.name}</td>
              <td>{user.websiteUrl}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.isAdmin ? 'Yes' : 'No'}</td>
              <td>{user.receiveNotifications ? 'Yes' : 'No'}</td>
              <td>{user.receiveAdditionalNotifications ? 'Yes' : 'No'} <a href="/" onClick={(e) => { e.preventDefault(); this.toggleAdditionalNotifications(user); }}>(toggle)</a></td>
              <td><a href={`/users/${user.id}/reviews`}>feedback</a>{` (\u2605 ${user.averageRating.toFixed(2)})`}</td>
              <td>{user.notificationDelay}</td>
              <td>
                {!user.isAdmin && <Button
                  onClick={ () => {
                      this.showChangePasswordUserPopup(user)
                  }}
                  variant="primary"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  Change Password
                </Button>}
              </td>
              <td>
                <Button
                  onClick={ () => {
                      this.editUser(user)
                  }}
                  variant="primary"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  Edit
                </Button>
              </td>
              <td>
                <Button
                  onClick={ () => {
                      this.showDeleteUserPopup(user)
                  }}
                  variant="primary"
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
          <Link to="/adduser">
            <Button variant="primary" onClick={ () => {} }>
              Add New User
            </Button>
          </Link>
          {popupDeleteUser &&
            <PopupAlert
              title="Delete User"
              body={popupText}
              selectedOption={(index) => {
                if (index === 0) {
                  this.setState({ popupDeleteUser: undefined })
                }
                else {
                  this.deleteUser(popupDeleteUser)
                }
              }}
            />
          }
          {changePasswordUser && !popupDeleteUser &&
            <ChangePasswordPopupAlert
              user={changePasswordUser}
              changePassword={(success, password) => {
                if (success) {
                  this.changePassword(changePasswordUser, password)
                }
                this.setState({ changePasswordUser: undefined })
              }}
            />
          }
      </div>
    );
  }
}

export default withRouter(Users);
