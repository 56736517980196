import 'bootstrap/dist/css/bootstrap.min.css';
import './style/shared.css';
import React from 'react'
import { dateToDateTimeString, dateToDateString } from '../utilities/DateFormatting.js'
import { enumToPresentableString, userToPresentableName, getSignedInEmail } from '../utilities/StringUtils.js'
import { bucketClaimsByDay } from '../utilities/ClaimUtils.js'
import { Table, Button } from 'react-bootstrap';
import PopupAlert from './PopupAlert.js'
import EditClaim from './EditClaim.js'
import { GetTodayClaims, GetArchivedClaims, ClaimFile } from '../network/ApiManager'
import { withRouter } from 'react-router';

class Claims extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popupClaim: null, editClaim: null, claims: [] }
    this.selectClaim = this.selectClaim.bind(this);
    this.deselectClaim = this.deselectClaim.bind(this);
    this.refreshClaims = this.refreshClaims.bind(this);
    this.rowsForClaim = this.rowsForClaim.bind(this);
    this.openEditClaim = this.openEditClaim.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  componentDidMount() {
    this.interval = setInterval(() => this.refreshClaims(), 120000)
    this.refreshClaims()
  }

  refreshClaims() {
    console.log(`Refreshing claims... (${new Date()})`);

    const { isAdmin } = this.props;

    if (isAdmin) {
      GetArchivedClaims((claims) => {
         const buckets = bucketClaimsByDay(claims)
         this.setState({ claims: buckets })
      }, (error) => {
          console.error(`${JSON.stringify(error)}`);
      })
    }
    else {
      GetTodayClaims((claims) => {
         const buckets = bucketClaimsByDay(claims)
         this.setState({ claims: buckets })
      }, (error) => {
          console.error(`${JSON.stringify(error)}`);
      })
    }
  }

  openEditClaim(claim) {
    const signedInEmail = getSignedInEmail()
    const { isAdmin } = this.props;
    const isClaimOwner = (claim.owner && signedInEmail && claim.owner.email === signedInEmail)
    if (isClaimOwner || isAdmin) {
      this.setState({ editClaim: claim, popupClaim: null })
    }
  }

  selectClaim(claim) {
    const { isAdmin } = this.props;
    if (!claim.ownership || isAdmin) {
      this.setState({ popupClaim: claim, editClaim: null })
    }
  }

  editClaim(claim) {
    if (claim) {
      const updatedClaim = claim;
      const claims = this.state.claims.map(cs => cs.map(c => c.id === updatedClaim.id ? updatedClaim : c));

      this.setState({ claims, popupClaim: null, editClaim: null })
    }
    else {
      this.setState({ editClaim: null, popupClaim: null })
    }
  }

  deselectClaim(claim) {
    if (claim) {
      ClaimFile(claim.id, (updatedClaim) => {
        const claims = this.state.claims.map(cs => cs.map(c => c.id === updatedClaim.id ? updatedClaim : c));
        this.setState({ popupClaim: null, editClaim: null, claims })
      }, (error) => {
        alert(error)
      })
    }
    else {
      this.setState({ popupClaim: null, editClaim: null })
    }
  }

  linkToClaim(claim) {
    if (claim.pdfUrl) {
      const maxDisplayLength = 200
      const isS3 = claim.pdfUrl.includes(".s3.us-east-2.amazonaws.com")
      const name = isS3 ? claim.pdfUrl.split('/').pop() : `${claim.pdfUrl.substring(0, maxDisplayLength)}${claim.pdfUrl.length > maxDisplayLength ? '...' : ''}`
      return (<a target="_blank" rel="noreferrer" href={`${claim.pdfUrl}`}>{name}</a>)
    }
    else {
      return (<div/>)
    }
  }

  rowsForClaim(claim) {
    const { isAdmin } = this.props;
    const signedInEmail = getSignedInEmail()
    const isClaimOwner = (claim.owner && signedInEmail && claim.owner.email === signedInEmail)

    const renderActionCell = () => {
      if (isAdmin) {
        return (<td>
          <div>
            <Button
              onClick={ () => {
                  this.openEditClaim(claim)
              }}
              variant="primary"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              Edit
            </Button>
            {!claim.owner &&
            <Button
              onClick={ () => {
                  this.selectClaim(claim)
              }}
              variant="primary"
              style={{
                display: 'block',
                marginTop: '10px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              Claim
            </Button>}
          </div>
        </td>)
      }
      else if (!isAdmin && !claim.owner) {
        return (
          <td>
              <Button
                onClick={ () => {
                    this.selectClaim(claim)
                }}
                variant="primary"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                Claim
              </Button>
            </td>
        )
      }
      else if (!isAdmin && isClaimOwner) {
        return (<td>
          <Button
            onClick={ () => {
                this.openEditClaim(claim)
            }}
            variant="primary"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            Notes
          </Button>
        </td>)
      }
      else {
        return (<td></td>)
      }
    }

    const reviewLink = `/claim/${claim.id}/review`
    let averageRating = 0.0
    if (claim.review) {
      const { speedOfService, qualityOfReview, friendlinessOfLawyer } = claim.review
      averageRating = (speedOfService + qualityOfReview + friendlinessOfLawyer) / 3.0
    }

    return (
        <tr
          key={claim.id}
          style={{ backgroundColor: claim.owner ? '#FFF2CC' : '#D9E1F2' }}
        >
          {renderActionCell()}
          <td style={{ maxWidth: '120px', minWidth: '120px', wordWrap: 'break-word' }}>{enumToPresentableString(claim.requestType)}</td>
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word', overflow: 'hidden' }} >{this.linkToClaim(claim)}</td>
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word' }}>{claim.senderDescriptor}</td>
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word' }}>{claim.howDidYouHearAboutUs}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{dateToDateTimeString(claim.timeReceived)}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{dateToDateTimeString(claim.timeClaimed)}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{dateToDateTimeString(claim.timeResponded)}</td>
          {isAdmin && <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{enumToPresentableString(claim.fileRetainedStatus) || 'Pending'}</td>}
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word' }}>{userToPresentableName(claim.owner)}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'break-word' }}>{enumToPresentableString(claim.clientType)}</td>
          <td style={{ maxWidth: '200px', minWidth: '200px', wordWrap: 'break-word' }}>{claim.trackingEmail}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'normal' }}>{claim.lawyerNotes}</td>
          <td style={{ maxWidth: '100px', minWidth: '100px', wordWrap: 'normal' }}>{claim.adminNotes}</td>
          {((isAdmin || isClaimOwner) && claim.hasReview ? <td style={{ minWidth: '100px', maxWidth: '100px', wordWrap: 'break-word' }}>{` (\u2605 ${averageRating.toFixed(2)})`}<br/><a href={reviewLink}>More Details</a></td> : <td><div/></td>)}
        </tr>
    );
  }

  render() {
    const { popupClaim, editClaim } = this.state;
    const claimsByDay = this.state.claims;
    const { isAdmin } = this.props;

    if (isAdmin && editClaim) {
      return (<EditClaim isAdmin={isAdmin} claim={editClaim} didUpdateClaim={(claim) => this.editClaim(claim)} />);
    }
    if (editClaim && editClaim.owner) {
      return (<EditClaim isAdmin={isAdmin} claim={editClaim} didUpdateClaim={(claim) => this.editClaim(claim)} />);
    }

    const noClaims = claimsByDay.length === 0

    return (
      <div style={{ padding: '20px' }}>
        {noClaims && <div>No Claims</div>}
        {claimsByDay.map((claims) => (
        <Table
            key={claims[0].id}
            style={{
              marginBottom: -1,
              width: '100%'

            }}
        >
          <thead>
            <tr style={{ width: '100%' }}>
              <th colSpan={isAdmin ? '15' : '14'}>{dateToDateString(claims[0].timeReceived)}</th>
            </tr>
            <tr>
              <th/>
              <th>File Type</th>
              <th>Claim File</th>
              <th>Sender Info</th>
              <th>Source</th>
              <th>Time Received</th>
              <th>Time Claimed</th>
              <th>Response Time</th>
              {isAdmin && <th>File Retained</th>}
              <th>Ownership</th>
              <th>Client Type</th>
              <th>Tracking Email</th>
              <th>Lawyer Notes</th>
              <th>Admin Notes</th>
              <th>Client Feedback</th>
            </tr>
          </thead>
          <tbody>
            {claims.map((claim) => ( this.rowsForClaim(claim) ))}
          </tbody>
        </Table>
        ))}
        {popupClaim &&
          <PopupAlert
            title="Claim File"
            body="Are you sure you wish to claim this file? (Once assigned to you, this file is yours and cannot be reassigned to anyone else)"
            selectedOption={(index) => {
              this.deselectClaim(index > 0 ? popupClaim : null)
            }}
          />
        }
      </div>
    );
  }
}

export default withRouter(Claims);
